import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const RiskManagement = () => {
    return (
        <Layout>
            <Seo title="Risk Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Risk Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        Ship Owners and Cargo Owners (Charterers) in a global market place face multiple challenges.
                                        Amid rapid change, search for enhanced performance, access to liquidity, cost containment and
                                        hedging of exposures to counterparties, time charter rates as well as bunker price movements is
                                        continuous.
                                    </p>
                                    <p>
                                        At Delta, we tackle these challenges head-on and provide solutions with quality execution,
                                        transparent trading and hedging of your exposures.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/container1.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default RiskManagement
